import { ProductCategory } from "~/components/ecommerce/types/ProductCategory";
import safeEcommerceUrl from "~/helpers/ecommerce/safeEcommerceUrl.js";

const localizeNettbutikkenImageUrl = (url) => {
	if (url) {
		return url;
	}
};

export default (product, siteSegment, { monthlyPriceFirst = false, hidePayNow = false } = {}) => {
	if (!product) return {};

	const isBusiness = siteSegment === "business";

	const mappedVariants = product.variants?.map((variant) => {
		return {
			...variant,
			priority: variant?.priority || 50,
			image: {
				image_md: {
					src: localizeNettbutikkenImageUrl(variant.campaignImage || variant.imageLink, siteSegment),
					height: 175,
				},
				alt: product.title && formatName(product.title),
			},
			link: {
				externalLink: variant.link,
			},
		};
	});

	// Fix broken bluestone links with missing slash
	if (product.link && product.link.includes("bsinfo.no") && !product.link.includes("bsinfo.no/"))
		product.link = product.link.replace("bsinfo.no", "bsinfo.no/");

	if (!product.link) {
		product.link = safeEcommerceUrl(product?.category, product?.brand, product?.title, product?.condition);
	}

	let mappedProduct = {
		productId: product.id,
		productImage: {
			image_md: {
				src: localizeNettbutikkenImageUrl(product.campaignImage || product.imageLink, siteSegment),
			},
			alt: product.title && formatName(product.title),
		},
		productLink: {
			externalLink: product.link,
		},
		productName: product.cleanName || (product.title && formatName(product.title)),
		rawProductName: product.title,
		productPrice: [],
		productPriceWithVat: [],
		productDescription: cleanHtml(product.descriptionSummary) || product.description,
		productItemGroupId: product.itemGroupId,
		productLabel:
			product?.prices?.memberPriceCampaign?.campaignData?.badgeText ||
			product?.prices?.campaign?.campaignData?.badgeText,
		variants: mappedVariants,
		rating: product.rating,
		reviewCount: product.reviewCount,
		salePrice: product.salePriceWithVat,
		preOrder: product.preOrder,
		brand: product.brand,
		rootCategory: product.rootCategory,
		additionalfilters: product.additionalfilters,
		categories: product.categories,
		condition: product.condition,
		inventory: product.inventory,
		attributes: product.attributes,
		installmentsInMonths: product.installmentsInMonths,
		priority: isNaN(product.priority) ? 50 : product.priority,
		type: product.type,
		stockReplacementText: product.stockReplacementText,
		cardStockReplacementText: product.cardStockReplacementText,
		unOrderable: product.unOrderable,
		disableClickAndCollect: product.disableClickAndCollect,
		ignoreInventory: product.ignoreInventory,
		stockThreshold: product.stockThreshold,
		disablePorterbuddy: product.disablePorterbuddy,
		bazaarVoiceId: product.bazaarVoiceId,
		memberPrice: product?.memberPrice,
		preConfigBundles: product?.preConfigBundles,
		model: product.model,
		price: product.priceWithVat,
		swapMonthlyPrice: product?.swapMonthlyPrice || undefined,
		swapDiscountedMonthlyPrice: product?.swapDiscountedMonthlyPrice || undefined,
		swapDiscountedTotalPrice: product?.swapDiscountedTotalPrice || undefined,
		prices: product?.prices,
	};

	let payNow = {
		// text: product.preOrder ? 'Reserver nå' : 'Betal nå', // This might return later
		text: "Betal nå",
		price: product.price && `${product.salePrice ? "Før:" : ""} ${formatPrice(Math.round(product.price))}`,
		salePrice: product.salePrice && `Nå: ${formatPrice(Math.round(product.salePrice))}`,
		memberPrice: product?.memberPrice ? product.memberPrice : undefined,
	};

	let payNowVat = {
		// text: product.preOrder ? 'Reserver nå' : 'Betal nå', // This might return later
		text: "Betal nå",
		price:
			product.priceWithVat &&
			`${product.salePriceWithVat ? "Før:" : ""} ${formatPrice(Math.round(product.priceWithVat))}`,
		salePrice: product.salePriceWithVat && `Nå: ${formatPrice(Math.round(product.salePriceWithVat))}`,
	};

	// Only hide "Pay now" if there's a monthly price to display
	if (!hidePayNow || !product.swapMonthlyPrice) {
		mappedProduct.productPrice.push(payNow);
		mappedProduct.productPriceWithVat.push(payNowVat);
	}

	if (product.swapMonthlyPrice) {
		const swapDiscountedTotalPriceBackup = product.swapDiscountedMonthlyPrice * 24;
		let swap = {
			text: product.category === ProductCategory.WATCH ? "Nedbetaling" : isBusiness ? "SWAP Bedrift" : "SWAP",

			price: `${product.swapDiscountedMonthlyPrice ? "Før:" : ""} ${formatPrice(product.swapMonthlyPrice)} /md.`,
			salePrice: product.swapDiscountedMonthlyPrice && `Nå: ${formatPrice(product.swapDiscountedMonthlyPrice)} /md.`,
			additionalInfo:
				!isBusiness &&
				`Totalt etter 24 md. ${formatPrice(
					product.swapDiscountedTotalPrice || swapDiscountedTotalPriceBackup || product.swapTotalPrice,
				)}`,
			extraAdditionalInfo: "Abo. Uten binding kommer i tillegg",
		};

		mappedProduct.productPrice.push(swap);
		mappedProduct.productPriceWithVat.push(swap);
	}

	if (product.installmentsInMonths) {
		mappedProduct.productPriceWithVat.push({
			text: "Nedbetaling",
			price: `Fra ${formatPrice(
				Math.round((product?.salePriceWithVat || product.priceWithVat) / product.installmentsInMonths),
			)} /md.`,
			additionalInfo: `${formatPrice(product?.salePriceWithVat || product.priceWithVat)}/${
				product.installmentsInMonths
			} /md.`,
		});
	}

	// If monthly price is selected in Gizmo, show this as the initial selection
	if (monthlyPriceFirst) {
		mappedProduct.productPriceWithVat.reverse();
		mappedProduct.productPrice.reverse();
	}

	mappedProduct.swap = !!(monthlyPriceFirst && product.swapMonthlyPrice);
	mappedProduct.parentCategory = product.parentCategory;
	mappedProduct.category = product.category;
	mappedProduct.categorynames = product.categorynames;

	return mappedProduct;
};

export function formatPrice(price) {
	if (price === "" || typeof price === "undefined") return price;

	price = price.toString().replace(/\..*/, "");

	if (isNaN(parseFloat(price)) || !isFinite(price)) return price;

	price = `${addSeparators(price, " ")},–`;
	return price;
}

function formatName(name) {
	// Remove information after comma, e.g. color
	const commaPos = name.indexOf(", ");
	if (commaPos > -1) name = name.substr(0, commaPos);

	// Remove storage from product name
	const storagePos = name.search(/\d+GB/gi);
	if (storagePos > -1) name = name.substr(0, storagePos);

	return name.trim();
}

function addSeparators(price, separator = ".") {
	// Insert a separator before every 3rd number (starting from the end)
	// Turns e.g "16899" into "16.899"
	return price
		.split("")
		.reverse()
		.join("")
		.match(/.{1,3}/g)
		.map((x) => {
			return x.split("").reverse().join("");
		})
		.reverse()
		.join(separator);
}

function cleanHtml(text) {
	return text
		?.replace(/<div.*?>.*?<\/div>/gi, "")
		.replace(/<p.*?>\s<\/p>/gi, "")
		.trim();
}
